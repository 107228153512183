import styled from "styled-components"

const StyledCryptoPayment = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 1.1rem;
  min-width: ${({size}) => size}px !important;


  .cryptopaymentaddressspan {
    background-color: ${({theme}) => theme.bgColor} !important;
    color: ${({theme}) => theme.mainColor} !important;
    border: 0.09rem solid ${({theme}) => theme.mainColor} !important;
    padding: 0.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 0.28571429rem;
  }
  .cryptopaymentaddressspan {
    cursor: pointer;
    overflow-wrap: break-word;
    width: ${({size}) => size}px !important;
  }

`
export default StyledCryptoPayment
