import React, { useRef, useEffect, useState } from "react"
import QRCode from "qrcode.react"
import  Button  from "src/components/Button"
import  Dropdown  from "src/components/Dropdown"

import StyledCryptoPayment from "./styles"
import { CopyToClipboard } from "react-copy-to-clipboard"

const CryptoPayment = ({
  theme = { mainColor: "#fff" },
  currency,
  currencyOptions,
  size,
  setCurrency,
}) => {
  const inputRef = useRef(null)
  const [buttonContent, setButtonContent] = useState("copy address")
  const [buttonContentCopied, setButtonContentCopied] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      if (buttonContentCopied) {
        setButtonContent("copy address")
        setButtonContentCopied(false)
      }
    }, 500)
  }, [buttonContentCopied])

  return (
    <StyledCryptoPayment size={size} theme={theme} width={"100%"}>
      <Dropdown
        fluid
        scrolling
        selection
        value={currency.value}
        options={currencyOptions}
        onChange={(e, v) => {
          setButtonContent("copy address")
          setButtonContentCopied(false)
          let currency = currencyOptions.find(currencyOption => {
            return currencyOption.key === v.value
          })
          setCurrency(currency)
        }}
      />
      <div style={{ width: `${size}px`, height: `${size}px` }}>
        <QRCode
          value={currency.address ? currency.address : ""}
          size={size || 200}
          bgColor={theme.mainColor}
          renderAs="canvas"
          level="M"
          includeMargin={true}
        />
      </div>

      <span
        className="cryptopaymentaddressspan"
        value={currency.address}
        ref={inputRef}
      >
        {currency.address}
      </span>
      <CopyToClipboard
        text={currency.address}
        onCopy={() => {
          setButtonContent("copied!")
          setButtonContentCopied(true)
        }}
      >
        <Button
          basic={true}
          labelPosition="right"
          icon="copy"
          content={buttonContent}
          inverted={true}
          size="small"
          fluid
        ></Button>
      </CopyToClipboard>
    </StyledCryptoPayment>
  )
}

export default CryptoPayment
