import React from 'react'
import StyledDropdown from "./styles"

 const Dropdown = (props) => {
    return (
        <StyledDropdown {...props}/>
    )
}

export default Dropdown
