import React, { useState, useEffect } from "react"
import { ThemeProvider } from "styled-components"
import { theme } from "../styles"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "src/components/seo"
import CryptoPayment from "src/components/CryptoPayment"
import Measure from "react-measure"
import { GlobalStyle } from "../styles"
import Layout from "src/components/Layout"

const PaymentPage = props => {
  const data = useStaticQuery(graphql`
    query PaymentOptionsQuery {
      site {
        siteMetadata {
          currencyOptions {
            key
            value
            address
            text
          }
        }
      }
    }
  `)
  const [size, setSize] = useState()
  const [isReady, setIsReady] = useState(false)
  const [currency, setCurrency] = useState({})
  const [dimensions, setDimensions] = useState()
  const currencyOptions = data.site.siteMetadata.currencyOptions

  useEffect((prevProp, prevState) => {
    const currencyType = new URLSearchParams(window.location.search).get(
      "currency"
    )

    if (currencyType) {
      let foundCurrency = currencyOptions.find(currencyOption => {
        return currencyOption.key.toLowerCase() === currencyType.toLowerCase()
      })
      if (foundCurrency) {
        setCurrency(foundCurrency)
      }
    } else {
      setCurrency(currencyOptions[0])
    }
    let fontSize = parseInt(
      window.getComputedStyle(document.body).fontSize || 14
    )
    let size = fontSize * 30
    setSize(size)
  }, [])

  useEffect(
    (prevProp, prevState) => {
      let fontSize = parseInt(
        window.getComputedStyle(document.body).fontSize || 14
      )
      let size = fontSize * 30
      setSize(size)
      setIsReady(true)
    },
    [dimensions]
  )

  return (
    <ThemeProvider theme={theme}>
      <Measure
        bounds
        onResize={contentRect => {
          setDimensions({ dimensions: contentRect.bounds })
        }}
      >
        {({ measureRef }) => (
          <div ref={measureRef}>
            <Layout theme={theme} withBreadCrumb activePage="Payment">
              <GlobalStyle bodyMarginTop="0vw" />
              <SEO
                title="Crypto Payment"
                keywords={[
                  "btc",
                  "bitcoin",
                  "ethereum",
                  "eth",
                  "Waves",
                  "address",
                  "payment",
                  "cryptocurrency",
                ]}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  backgroundColor: "#000",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "row",
                  }}
                >
                  {isReady && (
                    <CryptoPayment
                      theme={theme}
                      size={size}
                      currency={currency || currencyOptions[0]}
                      currencyOptions={currencyOptions}
                      setCurrency={setCurrency}
                    ></CryptoPayment>
                  )}
                </div>
              </div>
            </Layout>
          </div>
        )}
      </Measure>
    </ThemeProvider>
  )
}

export default PaymentPage
