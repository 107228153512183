import styled from "styled-components"
import { Dropdown } from "semantic-ui-react"


const StyledDropdown = styled(Dropdown)`
background-color: ${({theme}) => theme.bgColor} !important;
color: ${({theme}) => theme.mainColor} !important;

border: 0.09rem solid ${({theme}) => theme.mainColor} !important;
margin-bottom: 0.5rem;
width: ${({size}) => size}px !important;

.selected,
.item,
.text {
  background-color: ${({theme}) => theme.bgColor} !important;
  color: ${({theme}) => theme.mainColor} !important;
}
  .item,
  .menu {
    border-color: ${({theme}) => theme.mainColor} !important;
    background-color: ${({theme}) => theme.bgColor} !important;
  }
  .selected.item {
    background-color: ${({theme}) => theme.mainColor} !important;
    color: ${({theme}) => theme.bgColor} !important;
  }
  .selected.item .text {
    background-color: ${({theme}) => theme.mainColor} !important;
    color: ${({theme}) => theme.bgColor} !important;
  }

`
export default StyledDropdown
